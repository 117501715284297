import request from '@/utils/request'

// 获取 学历
export function geteducationdataRequest(params) {
    return request({
      url: '/admin/sysdicdata/geteducationdata',
      method: 'get',
      params
    })
  }

// 获取 字典信息
export function getdicdtabykeyRequest(params) {
    return request({
      url: '/admin/sysdicdata/getdicdtabykey',
      method: 'get',
      params
    })
  }

  