<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>订单管理</el-breadcrumb-item>
            <el-breadcrumb-item>订单详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form ref="form" :model="form" label-width="140px" size="mini">
        <el-form-item label="">
          <el-steps :space="400" :active="form.states" finish-status="success">
            <el-step title="未付款"></el-step>
            <el-step title="待发货"></el-step>
            <el-step title="已发货"></el-step>
            <el-step title="已完成"></el-step>
            <el-step title="已取消"></el-step>
          </el-steps>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form-item label="订单号">{{ form.orderno }}</el-form-item>
              <el-form-item label="支付单号">{{ form.ordernopay }}</el-form-item>
              <el-form-item label="退款单号">{{ form.out_refund_no }}</el-form-item>
              <el-form-item label="下单人">{{ form.nickname }}</el-form-item>
              <el-form-item label="下单人手机号">{{ form.userphone }}</el-form-item>
              <el-form-item label="收件人">{{ form.collectname }}</el-form-item>
              <el-form-item label="收件人电话">{{ form.collectphone }}</el-form-item>
              <el-form-item label="收件人地址">{{ form.collectprovince }}-{{ form.collectcity }}-{{ form.collectarea
              }}-{{ form.collectaddress }}</el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form-item label="订单来源">{{ ['普通订单', '积分兑换订单', '礼包订单'][form.ordertype] }}</el-form-item>

              <el-form-item label="付款方式">{{ ["", "", "线下到付", "余额支付", '微信支付', '积分支付', '免支付'][form.paytype] }}
              </el-form-item>
              <el-form-item label="支付状态">{{ ['未付款', '已付款', '免支付', '已退款'][form.ispay] }}</el-form-item>

              <el-form-item label="商品金额">{{ form.totalmoney }}</el-form-item>

              <el-form-item label="实际支付">
                <span v-if="form.paytype == 4">{{ form.totalpaymoney }}元</span>
                <span v-if="form.paytype == 5">{{ form.payintegral }}积分</span>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form-item label="创建时间">{{ form.addtime }}</el-form-item>
              <el-form-item label="支付时间">{{ form.paytime }}</el-form-item>
              <el-form-item label="发货时间">{{ form.delivertime }}</el-form-item>
              <el-form-item v-if="form.states == 3" label="完成时间">{{ form.hxtime }}</el-form-item>
              <el-form-item v-if="form.states == 4" label="取消时间">{{ form.hxtime }}</el-form-item>
              <el-form-item label="退款时间">{{ form.out_refund_notime }}</el-form-item>
              <el-form-item label="快递名称">{{ form.expressName }}</el-form-item>
              <el-form-item label="快递单号">{{ form.expressnum }}</el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-form-item label="门店">{{ form.shopinfo.name }}</el-form-item>
              <el-form-item label="联系人">{{ form.shopinfo.shopname }}</el-form-item>
              <el-form-item label="电话">{{ form.shopinfo.phone }}</el-form-item>
              <el-form-item label="门店地址">{{ form.shopinfo.shopaddress }}</el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-form-item label="明细">
          <el-table :data="orderitemdata">
            <el-table-column label="商品略图" width="110" align="center">
              <template slot-scope="scope">
                <el-image :src="scope.row.productimg" alt="图片">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column property="productname" label="商品名称"></el-table-column>
            <el-table-column property="productprice" label="单价">
              <template slot-scope="scope">
                {{ scope.row.productprice }}
                <el-tag v-if="scope.row.productunit">/{{ scope.row.productunit }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column property="productnum" label="购买数量"></el-table-column>
            <el-table-column prop="itemtype" label="性质" align="center">
              <template slot-scope="scope">{{ ["正常商品", "赠品", "礼品"][scope.row.itemtype] }}</template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getdatilRequest } from "@/api/fj_order";
import { getdicdtabykeyRequest } from "@/api/sysdicdata";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Useredit",

  data() {
    return {
      form: { orderno: '', orderbagno: '' },
      orderitemdata: []
    };
  },

  created() {
    this.form.orderno = this.$route.query["orderno"];
    this.form.orderbagno = this.$route.query["orderbagno"];
    this.getdatil();
  },

  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilRequest(this.form).then(res => {
        this.form = res.data;
        this.orderitemdata = res.data.orderitem;
      });
    },
    geteducationdata(groupname) {
      getdicdtabykeyRequest({ groupname: groupname }).then(res => {
        console.log(res);
        if (groupname == "vipgradekey") {
          res.data.shift();
          res.data = res.data.map(item => {
            return {
              value: parseInt(item.value),
              name: item.name
            };
          });

          this.vipdata = res.data;
        }
      });
    },
    save() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        editRequest(this.form).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },

  validations: {
    form: {}
  }
};
</script> 

<style lang="less" scoped>

</style>